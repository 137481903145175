export default {
    TAGS_ARRAY: [
      { value: "", text: "" },
      { value: 0, text: "Авторский" },
      { value: 1, text: "Новинка" },
      { value: 2, text: "Монобукет" }, 
      { value: 3, text: "Букет с витрины" },
      { value: 4, text: "Срочная доставка" },
      { value: 5, text: "Большой LUX" }, 
      { value: 6, text: "Мини-букет" },
      { value: 7, text: "Хит продаж" },
      { value: 8, text: "Недорогой букет" }, 
      { value: 9, text: "Душистый" },
      { value: 10, text: "Крашеный" },
    ],

    COMPOSITION_TIPS_ARRAY: [ 
      { value: 0, text: "Букет" },
      { value: 1, text: "Композиция" }, 
    ]
}