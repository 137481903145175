import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation  } from "react-router-dom";
import { connect } from "react-redux";
import OrderConstant from "../../constants/OrderConstant";
import {
  getAllUserOrders as getAllUserOrdersAction,
  getFilteredUserOrders as getFilteredUserOrdersAction,
  getOrderById as getOrderByIdAction,
  updateOrder as updateOrderAction,
  addOrder as addOrderAction,
  doneOrder as doneOrderAction,
  setOrder as setOrderAction,
  getComposition as getCompositionAction,
  addSpecialComposition as addSpecialCompositionAction,
  destroySpecialComposition as destroySpecialCompositionAction,
  setLoaderState as setLoaderStateAction,
  sendSms as sendSmsAction,
} from "../../redux/modules/order";
import {
  getDealComments as getDealCommentsAction,
  addComment as addCommentAction,
} from "../../redux/modules/comment";
import { getDealLogs as getDealLogsAction } from "../../redux/modules/logs";
import {
  filterUser as filterUserAction,
  getWorkers as getWorkersAction,
  getManager as getManagerAction,
  editUserData as editUserDataAction,
  getStoredUser as getStoredUserAction,
} from "../../redux/modules/login";
import { getUsersByPhone as getUsersByPhoneAction } from "../../redux/modules/users";
import {
  getStorageListByIdArray as getStorageListByIdArrayAction,
  getStorageList as getStorageListAction,
  getCompositions as getCompositionsAction,
} from "../../redux/modules/storage";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { numberWithCommas } from "../../utilities/numbers";
import {
  toArrayClearList,
  inArrayValueByKey,
  mergeArray,
} from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiSelectDates from "../../components/select/UiSelectDates";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalDealAdd from "../../components/modals/UiModalDealAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./HomeStyle.scss";

function HomePage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let { orderId } = useParams();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [user, setUser] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedCompositionData, setSelectedCompositionData] = useState(null);
  const [compositionList, setCompositionList] = useState([]);

  const [dateDelivery, setDateDelivery] = useState(null);
  const [dateSelfDelivery, setDateSelfDelivery] = useState(null);

  const [ordersList, setOrdersList] = useState([]);

  const [modalDealAddACtive, setModalDealAddActive] = useState(false);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "Дата и\nвремя" },
    { sort: true, title: "Номер" },
    { sort: false, title: "Покупатель" }, 
    { sort: true, title: "Статус\nзаказа" },
    { sort: true, title: "Сумма" },
    { sort: true, title: "Доставка" },
    { sort: false, title: "Оплата" },

    { sort: false, title: "Курьер" },
    { sort: false, title: "Дата\nдоставки" },
    { sort: false, title: "Адрес\nдоставки" },
    { sort: false, title: "Комментарий" },
    { sort: false, title: "Склад" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {  
    props.getStoredUser().then((_user) => {  
      setUser(_user);
    }) 
  }, []);

  useEffect(() => {
    if (user) { 
          // props.setLoaderState(true);
          //props.getAllUserOrders(dateDelivery, dateSelfDelivery, page);
          props.getWorkers();
          props.getManager();
          props.getStorageList();
          props.getCompositions().then((res) => setCompositionList(res)); 
     
    }
  }, [user]);

  useEffect(() => { 
    if (user) { 
        props.getAllUserOrders(dateDelivery, dateSelfDelivery, page); 
    }
  }, [user, page, dateSelfDelivery, dateDelivery]);

  useEffect(() => {
    if (user) {
      if (orderId) {
        props.getOrderById(orderId).then((res) => {
          if (res) openDeal(res);
        });
      }
    }
  }, [user, orderId]);

  useEffect(() => {  
    if (props.order.ordersList) {
      if (props.order.ordersList[0] != ordersList[0]) {
        setMaxPage(
          props.order.ordersList[0]
            ? props.order.ordersList[0].total_page_count
            : 0
        );
        setOrdersList(props.order.ordersList);
      }
    }
  }, [props.order.ordersList, props.login.usersWorker]);

  useEffect(() => {
    if (ordersList) {
      let arr = [];
      ordersList.map((item) => {
        if (
          props.login.user.is_worker == 0 ||
          (props.login.user.is_worker == 1 &&
            props.login.user.id == item.worker_id &&
            item.status < 4)
        ) {
          arr.push([
            {
              button: "edit",
              color: "blue",
              openClick: () => {
                if (props.login.user.type == 0 || props.login.user.type == 1) {
                  openDeal(item);
                  navigate(`/orders/${item.id}`);
                }
              },
            },
            {
              text: `${formatDateDMY(item.created_at)}  ${formatDateHM(
                item.created_at
              )}`,
            },
            { text: `${item.id}` },
            { text: `${item.name ? item.name : ""} +${item.phone}` },
           
            props.login.user.is_worker == 0
              ? {
                  statusInfo: OrderConstant.ORDER_TYPES_NAMES[item.status],
                  statusColor: OrderConstant.ORDER_TYPES_COLORS[item.status],
                  statusList: OrderConstant.ORDER_TYPES_NAMES,
                  onStatusChange: (val) => {
                    let _item = item;
                    _item.status = val;
                    props.updateOrder(
                      _item,
                      OrderConstant.UPDATE_INFO_CHANGE_INFO
                    );
                  },
                }
              : {
                  button: "edit",
                  editText: OrderConstant.ORDER_TYPES_MANAGER_NAMES[1],

                  openClick: (val) => {
                    let _item = item;
                    _item.status = 4;
                    props.updateOrder(
                      _item,
                      OrderConstant.UPDATE_INFO_CHANGE_INFO
                    );
                  },
                },

            { text: `${numberWithCommas(item.price)} ₽` },
            { text: `${numberWithCommas(item.price_delivery)} ₽` },
            {
              payStatus: item.is_pay == 0 ? "wait" : "done",
              statusPayList: OrderConstant.ORDER_IS_PAY,
              onStatusPayChange: (val) => {
                let _item = item;
                _item.is_pay = val;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
              },
            },
            {
              curInfo: `${
                inArrayValueByKey(props.login.usersWorker, "id", item.worker_id)
                  ? inArrayValueByKey(
                      props.login.usersWorker,
                      "id",
                      item.worker_id
                    ).name
                  : "-"
              }`,
              curList: toArrayClearList(props.login.usersWorker, "name"),
              onCurChange: (val) => {
                let _item = item;
                _item.worker_id = val;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
              },
            },
            {
              text:
                item.moving_types == 0
                  ? `${formatDateDMY(item.date)}  ${item.time}`
                  : "Самовывоз",
            },
            { text: `${item.address_a ? item.address_a : "-"}` },
            { text: item.text ? item.text : "-" },
            {
              shipmentStatus:
                OrderConstant.SHIPMENT_POINT[item.shipment_point - 1],
              shipmentList: OrderConstant.SHIPMENT_POINT,
              onShipmentChange: (val) => {
                let _item = item;
                _item.shipment_point = val;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
              },
            },
          ]);
        }
      });
      setTableListOrders(arr);
    }
  }, [ordersList]);

  function searchNum(line) {
    if (line.length > 0)
      props.getFilteredUserOrders("deals.id", "=", line, 100);
    else props.getAllUserOrders(dateDelivery, dateSelfDelivery, page);
  }
  function searchPhone(line) {
    if (line.length > 0)
      props.getFilteredUserOrders("users.phone", "LIKE", `'%${line}%'`, 100);
    else props.getAllUserOrders(dateDelivery, dateSelfDelivery, page);
  }

  function openDeal(item) {
    item.worker_name = inArrayValueByKey(
      props.login.usersWorker,
      "id",
      item.worker_id
    )
      ? inArrayValueByKey(props.login.usersWorker, "id", item.worker_id).name
      : "";
    item.manager_name = inArrayValueByKey(
      props.login.usersManager,
      "id",
      item.manager_id
    )
      ? inArrayValueByKey(props.login.usersManager, "id", item.manager_id).name
      : "";
    props.getStorageListByIdArray(item.composition);
    item.isNew = false;
    props.setOrder(item);
    props.getComposition(item.id);
    setModalDealAddActive(true);
 
    setSelectedData(item);

    navigate(`/orders/${item.id}`)
  }

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Заказы" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Новый заказ"
            small={true}
            onClick={() => {
              if (props.login.user.type == 0 || props.login.user.type == 1) {
                navigate('/orders/add')
              }
            }}
          />
          <UiButtonColor
            color="teal"
            text="Экран доставки"
            small={true}
            onClick={() => window.open("/dashboard", "_blank")}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            placeholder="Поиск: Номер заказа "
            onChange={(val) => searchNum(val)}
          />
          <UiInputSearch
            placeholder="Поиск: Номер телефон"
            onChange={(val) => searchPhone(val)}
          />
        </div>
        <div className="page-search">
          <UiSelectDates
            title={"Фильтр по дате доставки"}
            empty={dateDelivery == null}
            onChange={(date) => {
              setDateSelfDelivery(null);
              setDateDelivery(date);
            }}
            date={dateDelivery}
          />

          <UiSelectDates
            title={"Фильтр по дате самовывоза"}
            empty={dateSelfDelivery == null}
            onChange={(date) => {
              setDateSelfDelivery(date);
              setDateDelivery(null);
            }}
            date={dateSelfDelivery}
          />
        </div>

        <UiTableAdaptive
          pageSize={20}
          page={page}
          onChange={(p) => setPage(p)}
          maxPage={maxPage}
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
        />
      </section> 
      {/* modal deal add */}
      <UiModalDealAdd
        modalActive={modalDealAddACtive}
        managers={props.login.usersManager}
        curriers={props.login.usersWorker}
        materials={props.storage.compositionList}
        storage={props.storage.list}
        data={selectedData}
        compositionData={props.order.compositionOrder}
        compositionList={compositionList}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        onCreate={(data) => {
          console.log(data);
          props.addOrder(data).then((res) => {
            // setModalDealAddActive(false);
            alert("Заказ создан");
            openDeal(res.response);
          });
        }}
        onCreateSpecial={(data, order) => {
          if (data.order_id){
            props.addSpecialComposition(data); 
          } else {
            props.addOrder(order).then((res) => {
              if (res.response) {
                data.order_id = res.response.id;
                props.setOrder(res.response);
                props.getComposition(res.response.id);
                setSelectedData(res.response);
                props.addSpecialComposition(data);
              }
            });
          }
        }}
        deleteComposition={(_id) => {
          props.destroySpecialComposition(_id).then(() => {
            props.getComposition(selectedData.id);
          });
        }}
        update={(data) => {
          console.log("update", data );
         
        }}
        editUser={(data) => {
          props.editUserData(data).then(() => {
            // setModalDealAddActive(false);
            props.getAllUserOrders(dateDelivery, dateSelfDelivery, page , false );
          });
        }}
        sendSms={(data) => {
          props.sendSms(data).then(() => {
            alert("Смс отправлен");
          });
        }}
        modalClose={() => {
           
          props.getAllUserOrders(dateDelivery, dateSelfDelivery, page , false );
          setModalDealAddActive(false);
        }}
      />
    </div>
  );
}

export default connect(
  ({ order, login, comment, logs, user, storage, settings }) => ({
    order,
    login,
    comment,
    logs,
    user,
    settings,
    storage,
  }),
  {
    setLoaderState: setLoaderStateAction,
    getStoredUser: getStoredUserAction,

    getAllUserOrders: getAllUserOrdersAction,
    getFilteredUserOrders: getFilteredUserOrdersAction,
    updateOrder: updateOrderAction,
    addOrder: addOrderAction,
    doneOrder: doneOrderAction,
    setOrder: setOrderAction,
    getDealComments: getDealCommentsAction,
    addComment: addCommentAction,
    getDealLogs: getDealLogsAction,

    filterUser: filterUserAction,
    getWorkers: getWorkersAction,
    getManager: getManagerAction,
    getUsersByPhone: getUsersByPhoneAction,
    getStorageListByIdArray: getStorageListByIdArrayAction,

    getStorageList: getStorageListAction,
    getComposition: getCompositionAction,
    getCompositions: getCompositionsAction,
    getOrderById: getOrderByIdAction,

    addSpecialComposition: addSpecialCompositionAction,
    destroySpecialComposition: destroySpecialCompositionAction,
    editUserData: editUserDataAction,
    sendSms: sendSmsAction,
  }
)(HomePage);
