import OrderConstant from "../constants/OrderConstant";
import { safelyParseJSON } from "../services/Storage";

export const getInfo = (item) => {
    const volumeArray = OrderConstant.VOLUME_ARRAY;
    const trashArray = OrderConstant.TRASH_ARRAY
    const takArray = OrderConstant.TAK_ARRAY;
    const unbuildArray = OrderConstant.UNBUILD_ARRAY;
    const packArray = OrderConstant.PACK_ARRAY;
    const liftTypes = OrderConstant.LIFT_TYPES;
    
    const prr_types =  OrderConstant.PRR_TYPE;
    let arr = [];
    arr.push({ text: volumeArray[item.volume_car].text });
    arr.push( { text: `${item.movers} грузчика` });

    arr.push({ text: prr_types[item.prr_types].text });
    if(item.moving_types != 4) {
        if(item.is_hard_work) arr.push({ text: takArray[item.tak_value].text });
        if(item.is_assembly_disassembly)arr.push({ text: unbuildArray[item.unbuild_value].text });
        if(item.additional_work)arr.push({ text: packArray[item.pack_value].text });
    } else {
        arr.push({ text: trashArray[item.trash_value].text });
    }
    
    return arr;
}


export const getTotalPrice = (_arr, _arrSpecial, _delivery, _discount, _bonusDiscount,  _onlyArr = false) => {
  console.log(_arr, _arrSpecial, _delivery, _discount, _bonusDiscount)  
  let sum = 0;
    _arr.map((item, index) => {  
        if(item[4]) sum = sum +  parseFloat(item[4].text.replace(" ₽", "")) * 1;
    }) 
    _arrSpecial.map((item, index) => {  
        let _count = item[3].input ? item[3].input : item[3].text;
        if(item[4]) sum = sum +  parseFloat(item[4].text.replace(" ₽", "")) * parseFloat(_count);
    }) 
    if(!_onlyArr){ 
        sum = sum - sum * parseFloat(_discount/100);
        sum = sum + parseFloat(_delivery); 
        sum = sum - parseFloat(_bonusDiscount);
    } 
    return Math.round(sum);
}

export const getSalePrice = (item, c) => {
    let sum = 0;

    sum = sum + item.price * (c);
    /*
    if(item.sale == 0) { 
        sum = sum + item.price * (c);
    } else { 
        sum = sum + ( c >= item.sale_count ? item.sale : item.price) * (c);
    }
    */
    return Math.round(sum);
}


export const reorderBasket = (_arr) => {
    let arr = [];
    _arr.map((item) => {
       let f= -1;
       arr.map((item2, index2) => {
        if(item.title == item2.title) f = index2;
       })
       if(f == -1){
        item.count= 1;
        arr.push(item)
       } else {
        arr[f].count++;
       }
    })
    return arr;
}

export const resortOrderComposition = (_arr, _composition) => {
    console.log(_composition)
    let comp = (_composition ? _composition : "") .toString().split(",");
    let arr = [];
    let basket = []; 

    _arr.map((item, index) => { 
        comp.map((item2) => {
            if(item.id == item2) basket.push(item);
        })
    }) 

    basket.map((item) => {
        let f= -1;
        arr.map((item2, index2) => {
         if(item.title == item2.title) f = index2;
        })
        if(f == -1){
         item.count= 1;
         if(!item.images) item.images = item.imgs.split(",");
         arr.push(item)
        } else {
         arr[f].count++;
        }
     })

     return arr;
}



export const getPrice = (_item, _allCompositionList = []) => {
    let sum = 0;
  
    let priceArr = [];
    let price = _item.price;
    if (_item.composition) {
      console.log(_item)
      let selectedStorage =  (_item.composition);
      if (selectedStorage.length == 1) {
        let _selectedItem = null;
        if (_allCompositionList.length > 0) {
          _allCompositionList.map((_itemCL) => {
            if (_itemCL.custom_id == selectedStorage[0].custom_id ) _selectedItem = _itemCL;
          });
        } 
        if (_selectedItem) {
          priceArr = safelyParseJSON(_selectedItem.custom_sale_price);
          price = _selectedItem.custom_price;
          if (priceArr.length > 0) {
            priceArr.map((item) => { 
              if (item.count_lower <= _item.count && item.count_upper >= _item.count) {
                if (item.custom_price.includes("%")) {
                  let pr = item.custom_price.replace("%", "");
                  pr =  (_selectedItem.custom_price - (pr / 100) * _selectedItem.custom_price) *  _item.count;  
                  price = Math.round(pr) / _item.count; 
                } else {
                  price = item.custom_price;
                }
              }
            });
          }
        
          return  Math.round(price*_item.count) ;
        }
      }
    }
  
  
    if (_item.sale == 0) {
      sum = sum + _item.price * (_item.count ? _item.count : 1);
    } else {
      let _c = _item.count ? _item.count : 1;
      sum = sum + (_c >= _item.sale_count ? _item.sale : _item.price) * _c;
    }


    return sum;
  };