import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import demo0 from "../../assets/images/demo/1447_b.jpeg";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction,
  addStorage as addStorageAction,
  update as updateStorageAction,
} from "../../redux/modules/storage";

import {
  convertImages,
  fromArrayListKey,
  inArrayValueByKey,
} from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ProductsStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

function ProductsPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState(null);
  const [products, setProducts] = useState([]);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: true, title: "Сортировка" },
    { sort: true, title: "Номер" },
    { sort: false, title: "Название" },
    { sort: false, title: "Описание" },
    { sort: true, title: "Категория" },
    { sort: true, title: "Цена" },
    { sort: true, title: "Слайдер" },
    { sort: false, title: "Скрыть" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  function _load() {
    if (id == "all") props.getStorageList().then((data) => setProducts(data))
    else props.getStorageListByCat(id).then((data) => setProducts(data))
  }

  useEffect(() => {
    _load();
  }, [id]);

  useEffect(() => {
    let arr = [];
    products.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      arr.push([
        {
          button: "edit",
          onClick: () => {
            storeData("edit", item);
            navigate("/products/add");
          },
        },
        { image: images[0] ? Env.PUBLIC_URL + images[0] : null },
        {
          inputSort: `${item.sort}`,
          onBlurInput: (v) => {
            let _item = item;
            let s = _item.sort;
            if(v == 1 ){
              s++;
              _item.sort = s;
            } else {
              s--;
              _item.sort = s;
            } 
            props.update(_item).then(() => _load());
          },
        },
        { text: item.id },
        { text: item.title },
        { text: item.text },
        {
          statusInfo:
            props.category.list.length > 0
              ? inArrayValueByKey(props.category.list, "id", item.category)
                  .title
              : "",
          statusColor: "orange",
        },
        { text: `${item.price} ₽` },
        {
          button: item.is_slider != 0 ? "eye-open" : "eye",
          onClick: () => {
            let _item = item;
            _item.is_slider = item.is_slider == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          },
        },
        {
          button: item.is_active == 0 ? "eye-open" : "eye",
          onClick: () => {
            let _item = item;
            _item.is_active = item.is_active == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          },
        },
        {
          button: "delete",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.update(_item).then(() => _load());
            }
          },
        },
      ]);
    });
    setTableListOrders(arr);
  }, [products , props.storage.list, props.category.list]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText
            title={`Список товаров в категории: ${
              props.category.list.length > 0
                ? inArrayValueByKey(props.category.list, "id", id).title
                : ""
            }`}
          />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/products/add");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список категорий"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>
      {/* modal deal add */}
      <UiModalProductAdd
        modalActive={modalDealAddACtive}
        modalClose={() => setModalDealAddActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        onCreate={(data) => {
          props.addOrder(data).then(() => {
            setModalDealAddActive(false);
          });
        }}
      />
    </div>
  );
}

export default connect(
  ({ storage, login, comment, logs, user, settings, category }) => ({
    storage,
    login,
    comment,
    logs,
    user,
    settings,
    category,
  }),
  {
    getStorageList: getStorageListAction,
    getStorageListByCat: getStorageListByCatAction,
    addStorage: addStorageAction,
    update: updateStorageAction,
  }
)(ProductsPage);
