import { safelyParseJSON } from "../../../services/Storage";

export default {
 
 
   
    _remCustomImg: function (index, id , state,  setState) {
        let arr = state.addPhotos;
    
        arr.splice(index, 1);
        setState({
          ...state,
          addPhotos: arr,
        });
    },
    
    convertArrayToReplaces: function (_arr, order, replaces, setReplaces, updateReplaceItem, getStorageItem) {
        let arr = []; 
        if (_arr) {
          _arr.map((item, itemIndex) => {
              arr.push([
                {
                  button: "delete",
                  openClick: () => {
                    if (item.id){
                      let _item = item;
                      _item.is_del = 1;
                      updateReplaceItem(_item).then(() => {
                        getStorageItem(order.title_url).then((item) => {
                          if(item) setReplaces(item.replaces);
                        })
                      });
                    } else { 
                      let rem = replaces;
                      rem.splice(replaces.length - 1, 1);
                      setReplaces(rem); 
                    }
                  },
                },
      
                { text: `№${itemIndex + 1}` },
                { text: `${item.my_storage_item.title} -> ${item.my_storage_replace_item.title}` },
                { text: `${item.count}шт` },
                { text: item.composition ? `Для варианта #${item.composition}` : 'Для основного товара' },
              
              ]);
            }); 
    
    
        }
        return arr;
      },



       getTotalPrice: function (composition) {
        let price = 0;
        if (composition !== null) {
          composition.map((item) => {
            price = price + item.custom_count * item.custom_price;
          });
        }
        console.log("SS", composition)
        return price;
      },


       convertArrayToAdditional: function (_arr,  openClick) {
        let arr = [];
        _arr.map((item, itemIndex) => { 
        
          let composition = "";
          safelyParseJSON(item.custom_composition).map((item2,index) => {
          
            composition += `${item2.custom_title} ${item2.custom_count}шт ,`;
            
          })
          arr.push([
            {
              button: "delete",
              openClick: () => { 
                openClick(item.id, itemIndex);
               
              },
            },
    
            { text: `${item.custom_title} (${composition})` },
            { text: `${item.custom_price} ₽` },
            { text: item.custom_count },
          ]);
        });
        return arr;
      }

      
  };